import { useQuery } from '@apollo/client'
import { Card, Space } from 'antd'
import IframeResizer from 'iframe-resizer-react'
import React, { useEffect } from 'react'

import { IndexPageDocument } from '../../../generated/graphql'
import ErrorTracker from '../../../utils/ErrorTracker'
import { PageContainer } from '../../layouts'
import { Error500Template } from '../../templates/Error500Template'
import { DashboardModel } from './models'

export const IndexPage: React.FC = () => {
  const { loading, error, data } = useQuery(IndexPageDocument)

  useEffect(() => {
    if (error) ErrorTracker.trackError(error)
  }, [error])

  if (loading) return <PageContainer loading />
  if (error || !data) return <Error500Template />

  return <Container dashboard={data.topPageDashboard} />
}

//
// Container
//

type ContainerProps = {
  dashboard: DashboardModel
}
const Container: React.FC<ContainerProps> = ({ dashboard }) => (
  <Screen dashboard={dashboard} />
)

//
// Screen
//

type ScreenProps = {
  dashboard: DashboardModel
}
const Screen: React.FC<ScreenProps> = ({ dashboard }) => (
  <PageContainer>
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card>
        {/* iframe内で8pxのpaddingがあるので相殺 */}
        <div style={{ margin: '0 -8px' }}>
          <IframeResizer
            title="AnalyticsTicketReviews"
            src={dashboard.url}
            style={{
              width: '1px',
              minWidth: '100%',
            }}
            frameBorder={0}
            allowTransparency
          />
        </div>
      </Card>
    </Space>
  </PageContainer>
)
